<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        v-if="formieForm"
        class="formie-form"
    >
        <form
            ref="formRef"
            novalidate
            @submit.prevent="submit"
        >
            <div class="formie-form__header">
                <slot
                    name="formTitle"
                    :form-title="formieForm.title"
                    :display="formieForm.settings.displayFormTitle"
                >
                    <div
                        v-if="formieForm.settings.displayFormTitle"
                        class="formie-form__header__title"
                    >
                        <h2>
                            {{ formieForm.title }}
                        </h2>
                        <hr>
                    </div>
                </slot>

                <div
                    ref="errorsRef"
                    tabindex="-1"
                >
                    <div
                        v-show="formieForm.errorList.length"
                        class="formie-form__errors"
                        role="alert"
                    >
                        <div class="formie-form__errors__title">{{ translations[formStore.locale]['errorList'] }}</div>
                        <ul>
                            <li
                                v-for="item in formieForm.errorList"
                                :key="item.id"
                            >
                                <button
                                    v-for="(error, index) in item.errors"
                                    :key="`${item.id}-${index}`"
                                    type="button"
                                    @click="focusInputError(item.pageIndex, item.id)"
                                >
                                    {{ item.label }}: {{ error }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <nav
                    v-if="formieForm.settings.displayPageTabs"
                    class="formie-form__header__tabs"
                    aria-label="Tabs"
                >
                    <a
                        v-for="(page, index) in formieForm.pages"
                        :key="index"
                        href="#"
                        :class="index == formieForm.activePage ? 'active' : ''"
                        @click.prevent="
                            formStore.setActivePage(formieForm.handle, index)
                        "
                    >
                        {{ page.name }}
                    </a>
                </nav>

                <slot
                    name="formProgress"
                    :form-progress="formStore.progress(formieForm.handle)"
                    :display="formieForm.settings.displayPageProgress"
                >
                    <div
                        v-if="formieForm.settings.displayPageProgress"
                        class="formie-form__header__progress"
                    >
                        <div
                            class="formie-form__header__progress__bar"
                            :style="{
                                width: `${formStore.progress(formieForm.handle)}%`,
                            }"
                            role="progressbar"
                            :aria-valuenow="formStore.progress(formieForm.handle)"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        />
                    </div>
                </slot>
            </div>

            <FormiePage
                v-for="(page, index) in formieForm.pages"
                :key="index"
                :class="'formie-form__page-' + index"
                :form="formieForm"
                :page="page"
                :page-index="index"
            >
                <!-- Passing parent slots to child -->
                <template
                    v-for="(_, slotName) in $slots"
                    #[slotName]
                >
                    <slot :name="slotName" />
                </template>
            </FormiePage>
        </form>

        <div
            v-if="formieForm.message"
            class="formie-form__message"
            :class="{ error: formieForm.error }"
            v-html="formieForm.message"
        />
    </div>
</template>

<script setup>
import { ref, toRefs, defineComponent } from 'vue';
import { gql } from 'graphql-tag';
import { useGtm } from '@gtm-support/vue-gtm';
import { useFormStore } from '../store/form.js';
import { useValidator } from '../composables/validations';
import getFormQuery from '../graphql/GetForm.graphql';
import FormiePage from './FormiePage.vue';

const formStore = useFormStore();

defineComponent({
    components: {
        FormiePage
    }
});

const props = defineProps({
    formHandle: {
        type: String,
        required: true,
    },
    locale: {
        type: String,
        default: 'en',
    },
});
// Props to refs
const { formHandle, locale } = toRefs(props);

// Template refs
const formRef = ref(null);
const errorsRef = ref(null);

const { data } = await useAsyncQuery({
    query: getFormQuery,
    variables: { handle: formHandle.value },
});

const formieForm = ref(null);
formieForm.value = formStore.setForm(toValue(data).formieForm, locale.value);

const focusInputError = (pageIndex, id) => {
    formStore.setActivePage(formieForm.value.handle, pageIndex);

    // Waiting for the next tick to focus the input
    setTimeout(() => {
        formRef.value.querySelector(`[id="${id}"]`).focus();
    }, 0);
};

const handleSuccess = (form) => {
    form.loading = false;

    useGtm().trackEvent({
        event: 'form_submit_custom',
        formHandle: form.handle
    });

    // Submit action based on the form settings
    switch (form.settings.submitAction) {
    case 'url':
    case 'entry':
        window.location.href = form.settings.redirectUrl;

        // if (form.settings.submitActionTab === 'same-tab') {
        //     window.location.href = form.settings.redirectUrl;
        // } else {
        //     window.open(form.settings.redirectUrl, '_blank');
        // }
        break;

    case 'reload':
        window.location.reload();
        break;

    default:
        if (form.settings.submitActionFormHide) {
            formRef.value.style.display = 'none';
        }

        form.message = form.settings.submitActionMessageHtml;

        if (form.settings.submitActionMessageTimeout) {
            setTimeout(() => {
                form.message = null;
            }, form.settings.submitActionMessageTimeout * 1000);
        }
        break;
    }
};

const handleError = (form) => {
    form.error = true;
    form.message = form.settings.errorMessageHtml;
    form.loading = false;
};

const getFormMutation = (form) => {
    const mutationTypes = formStore.getFormMeta(form)
        .map(({ handle, inputTypeName }) => {
            return `$${handle}: ${inputTypeName}`;
        })
        .join(', ');

    const mutationValues = formStore.getFormMeta(form)
        .map(({ handle }) => {
            return `${handle}: $${handle}`;
        })
        .join(', ');

    const mutationHandle = `save_${form.handle}_Submission`;

    return gql`
        mutation FormMutation(${mutationTypes}) {
            ${mutationHandle}(${mutationValues}) {
                id
            }
        }
    `;
};

const { execute } = useAsyncMutation('formie');
const submit = async() => {
    const form = formStore.getForm(formHandle.value);

    // Import validation functions
    const { validate, reset, applyServerValidation } =
        useValidator(form.handle, formRef.value);

    // Reset form state
    form.loading = true;
    form.error = false;
    form.message = null;

    // Clear out validation errors
    reset();

    // Validate the form, for each page by it's class
    if (!validate(`formie-form__page-${form.activePage}`)) {
        form.loading = false;
        errorsRef.value.focus();
        return;
    }

    // Check if the form is on the last page
    if (form.activePage !== form.pages.length - 1) {
        formStore.setActivePage(form.handle, form.activePage + 1);
        scroll(0, 0);
        form.loading = false;
        return;
    }

    // Send the form data to the server
    try {
        const { data: response, error } = await execute({
            mutation: getFormMutation(form),
            variables: form.data
        });

        if (error.value) {
            throw error.value;
        }

        const { error: errors } = response.value;

        if (errors) {
            applyServerValidation(errors.message);
            handleError(form);
            errorsRef.value.focus();
            return;
        }

        handleSuccess(form);
    } catch (e) {
        handleError(form);
    }
};

const translations = ref({
    en: {
        errorList: 'These fields aren\'t valid',
    },
    nl: {
        errorList: 'Deze velden zijn nog niet correct ingevuld',
    },
});
</script>

<style lang="less" scoped>
.formie-form {
    .formie-form__header {
        margin-bottom: 2rem;
    }

    .formie-form__header__title {
        margin-bottom: 1rem;
    }

    .formie-form__errors {
        border: 1px solid red;
        background-color: rgba(255, 0, 0, .05);
        padding: 1rem 1.5rem;
        color: red;
        margin-bottom: 2rem;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                margin-bottom: 1rem;
            }
        }

        button {
            padding: 0;
            background: none;
            border: none;
            border-bottom: 1px solid currentColor;
            color: red;
            cursor: pointer;
            outline-offset: .1rem;
        }
    }

    .formie-form__errors__title {
        .typography-lead();
        margin-bottom: 1rem;
    }

    .formie-form__header__tabs {
        margin-bottom: 1rem;

        a {
            margin-right: 1rem;
        }
    }

    .formie-form__header__progress {
        border: 1px solid var(--color-primary);
        margin-bottom: 1rem;
    }

    .formie-form__header__progress__bar {
        height: .5rem;
        background-color: var(--color-primary);
        transition: width .5s;
    }

    .formie-form__message {
        margin-top: 1rem;

        &.error {
            color: red;
        }
    }
}
</style>
